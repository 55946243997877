import React, { Component } from "react"
import ReactDOM from "react-dom"
import './header.css';
import { Container, Row, Col } from 'reactstrap';
export class Header extends Component {
    static displayName = "Header";

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Row>
                    <Col className="headerDiv">
                        <img src='../images/logo.png' alt="Sim Angus Bulls and Heifers for Sale. 1078 Boone Rd, Burlington, NC, 27217" />
                    </Col>
                </Row>
            </>
        );
    }
}