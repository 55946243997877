import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Footer } from './Footer';
import { NavMenu } from './NavMenu';
import { Header } from './header';


export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <>
                <Header />
                <Container fluid>
                    {this.props.children}
                </Container>
                <Footer />
            </>
        );
    }
}
