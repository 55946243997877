import React, { Component } from 'react';
import { Collapse, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../custom.css';

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div class="footer">Copyright &copy; {new Date().getFullYear()} SimAngus Solution - 1078 Boone Rd. Burlington, NC, 27217
            </div>
        );
    }
}