import React, { Component } from "react"
import ReactDOM from "react-dom"
import './contact.css';
import { Container, Row, Col } from 'reactstrap';

export class Contact extends Component {
    constructor(params) {
        super(params);
    }
    render() {
        return (
            <>
                <Row>
                    <Col>
                        <p className='contactHeader'>For more information about our herd, contact:<br />
                            {/* <img src="./images/sof.jpg" alt="Sales Order Form" class='sof' /> */}
                        </p>
                    </Col>
                </Row>
                <Row xs="3" md="3" lg="3">
                    <Col className="right">
                        Jonathan Massey<br />
                        <a href='tel:336-260-2565'>336-260-2565</a><br />
                        <a href='mailto:simangussolution@gmail.com'>simangussolution@gmail.com</a><br />
                    </Col>
                    <Col><center><b>SALE:</b> Saturday, <b>November 4</b>, 2023 <b>1:00 PM</b></center></Col>
                    <Col>
                        Johnny Massey<br />
                        <a href='tel:336-214-4144'>336-214-4144</a><br />
                        <a href='mailto:johnnymassey.1@gmail.com'>johnnymassey.1@gmail.com</a>
                    </Col>
                </Row>
            </>
        );
    };
}