import React, { Component } from 'react';
import './logos.css';

export class Logo extends Component {
    constructor(props) {
        super();
        this.state = {
            logo: props.logo
        }
    };

    render() {
        if (this.state.logo.src === 'gplus') {
            return <img src='/images/rclogos/gplus.png' className='rclogos' alt='G+ Logo' />
        }
        else if (this.state.logo.src === 'atm') {
            return <img src='/images/rclogos/atm.png' className='rclogos' alt='ATM Logo' />
        }
        else if (this.state.logo.src === 'ace') {
            return <img src='/images/rclogos/ace.png' className='rclogos' alt='ACE Logo' />
        }
        else
            return <></>
    };
};
