import React, { Component } from 'react';
import Animal from './animal';
import data from '../data/lots.json';

export class Animals extends Component {
    constructor(props) {
        super(props);
    };

    render() {
        return (
            <>
                {data.map((animal, index) =>
                    <Animal animal={animal} count={index} />
                )}
            </>
        );
    };
}