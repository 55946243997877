import React, { Component } from "react"
import ReactDOM from "react-dom"

export class Gmaps extends Component {
    static displayName = "Gmaps";

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3220.9262962834428!2d-79.45884868445548!3d36.1683481107092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x8852d5c338e34c49%3A0x8638a50d631c8b4d!2s1078%20Boone%20Rd%2C%20Burlington%2C%20NC%2027217!3m2!1d36.168343799999995!2d-79.45666!5e0!3m2!1sen!2sus!4v1633562308611!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
            </>
        );
    }
}